<template>
  <div>
    <div v-if="staffWorkList === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <v-container
      v-else-if="staffWorkList.length === 0"
      class="text-center"
      fill-height
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-col>
          {{ $t('noStaffWorkHoursNeedConfirmation') }}
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-list style="max-height: 100vh; overflow-y: scroll;">
        <v-divider />
        <template v-for="(workToConfirm, index) in staffWorkList">
          <ConfirmWorkListItem
            v-if="!approvedWork.includes(index) && !failedToApprove.includes(index)"
            :work-to-confirm="workToConfirm"
            :key="'s-'+index"
            :processing="processing"
            @approveWork="approveWork"
            :index="index"
          />
          <v-divider :key="index" />
          <v-alert
            :key="'a-'+index"
            :value="approvedWork.includes(index)"
            style="padding:22px; margin: 0"
            type="success"
            transition="slide-x-transition"
          >
            {{ $t('workHasBeenApproved') }}
          </v-alert>
          <v-alert
            :key="'e-'+index"
            :value="failedToApprove.includes(index)"
            style="padding:22px; margin: 0"
            type="error"
            transition="slide-x-transition"
          >
            {{ $t('couldNotApproveWork') }}
          </v-alert>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import ConfirmWorkListItem from "@/components/staff/ConfirmWorkListItem"

export default {
	name: "ConfirmWorkList",
	components: {
		ConfirmWorkListItem
	},
	props: {
		staffWorkList: {
      type: Array,
      default: () => []
    }
	},
	data() {
		return {
			processing: false,
      approvedWork: [],
      failedToApprove: []
		}
	},
	methods: {

    approveWork(workToApprove, index) {
      this.processing = true
      this.$store.dispatch('approveWork', workToApprove).then(result => {
        if(result) {
          this.approvedWork.push(index)
          this.processing = false
        }
        else {
          this.failedToApprove.push(index)
          this.processing = false
        }
      }).catch(() => {
        this.failedToApprove.push(index)
        this.processing = false
      })
    }
  }
}
</script>

<style scoped>

</style>
