<template>
  <v-list-item
    two-line
  >
    <v-row
      align="center"
      justify="space-between"
    >
      <v-col
        class="py-0"
        cols="12"
        sm=""
        order="1"
        order-sm="1"
      >
        <v-list-item-content>
          <v-list-item-title v-if="work.staff.address !== null && work.staff.address.name_line !== null && !hideName">
            {{ work.staff.address.name_line }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-break text-wrap">
            <div v-if="$vuetify.breakpoint.lgAndUp">
              <div v-if="work.start_date">
                {{ $t('started') }}: {{ new Date(work.start_date).toLocaleString('en-GB') }}
              </div>
              <div v-if="work.end_date">
                {{ $t('ended') }}: {{ new Date(work.end_date).toLocaleString('en-GB') }}
              </div>
            </div>
            <div v-else>
              <div v-if="work.start_date">
                {{ $t('started') }}: <br v-if="$vuetify.breakpoint.sm">{{ new Date(work.start_date).toLocaleString('en-GB') }}
              </div>
              <div v-if="work.end_date">
                {{ $t('ended') }}: <br v-if="$vuetify.breakpoint.sm">{{ new Date(work.end_date).toLocaleString('en-GB') }}
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-col>
      <v-col
        order="2"
        style="max-width: 150px;"
      >
        <v-chip
          color="primary"
          label
          class="white--text"
          style="font-weight: 600;"
        >
          {{ locationName }}
        </v-chip>
      </v-col>
      <v-col
        order="2"
        style="max-width: 120px"
      >
        <v-chip
          v-if="work.requiresApproval === true && work.status === 'pending'"
          color="blue"
          label
          class="white--text pl-1"
          style="font-weight: 600; font-size: 70%;"
        >
          {{ $t('pendingApproval') }}
        </v-chip>
        <v-chip
          v-else-if="work.status === 'cancelled' || work.status === 'canceled'"
          color="error"
          label
          class="white--text"
          style="font-weight: 600;"
        >
          {{ $t('cancelled') }}
        </v-chip>
        <v-chip
          v-else
          color="success"
          label
          class="white--text"
          style="font-weight: 600;"
        >
          {{ $t('approved') }}
        </v-chip>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        sm=""
        order="10"
        order-sm="3"
      >
        <WorkHoursIndicatorBar
          v-if="work.type === 'log'"
          :work="work"
        />
        <v-chip
          v-if="work.type === 'sick_leave'"
          label
          color="yellow darken-2"
          style="font-weight: 600;"
        >
          <v-icon left>
            mdi-alert-outline
          </v-icon>
          <span>
            {{ $t('sickLeave') }}
          </span>
        </v-chip>
        <v-chip
          v-if="work.type === 'sick_leave'"
          label
          class="ml-2"
        >
          <span style="font-weight: 300;">
            {{ hours }}{{ $t('hoursInitial') }} {{ minutes }}{{ $t('minutesInitial') }}
          </span>
        </v-chip>
      </v-col>
      <v-col
        cols="auto"
        class="py-0"
        order="3"
        order-sm="10"
      >
        <v-btn
          color="error"
          text
          @click.prevent="emitClick"
          @click="emitCancel"
          :disabled="disabled"
          icon
        >
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="disabled"
          icon
          @click="emitClick"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-list-item-content v-if="false">
      <v-list-item-title v-if="work.staff.address !== null && work.staff.address.name_line !== null">
        {{ work.staff.address.name_line }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <span v-if="work.start_date">{{ $t('started') }}: {{ new Date(work.start_date).toLocaleString('en-GB') }}</span>
        <span> - </span>
        <span v-if="work.end_date">{{ $t('ended') }}: {{ new Date(work.end_date).toLocaleString('en-GB') }}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content v-if="false">
      <WorkHoursIndicatorBar :work="work" />
    </v-list-item-content>
    <v-list-item-action v-if="false">
      <v-btn icon>
        <v-icon color="grey lighten-1">
          mdi-information
        </v-icon>
      </v-btn>
    </v-list-item-action>
    <v-dialog
      v-model="showConfirmCancel"
      v-if="showConfirmCancel"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirmCancelWorkLog') }}
        </v-card-title>
        <v-card-text>
          {{ $t('areYouSureYouWantToCancelWork') }}
          <div
            class="pt-6"
            v-if="showError"
          >
            <v-banner
              color="error"
              dark
              icon="mdi-alert"
              rounded
              single-line
            >
              <div>{{ $t('couldNotCancelWork') }}</div>
              <div>{{ errorText }}</div>
              <template v-slot:actions>
                <v-btn
                  @click="clearError"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-banner>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="showConfirmCancel = false"
            color="error"
            large
            text
            :disabled="cancellingWork"
          >
            {{ $t('back') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="confirmedCancel"
            color="primary"
            large
            text
            :loading="cancellingWork"
            :disabled="disabled"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>

  import WorkHoursIndicatorBar from './WorkHoursIndicatorBar'

  export default {
    name: 'WorkListItem',
    props: {
      work: {
        type: Object,
        default: () => ({})
      },
      staff: {
        type: Object,
        default: () => ({})
      },
      disable: {
        type: Boolean,
        default: false
      },
      hideName: {
        type: Boolean,
        default: false
      }
    },
    components: {
      WorkHoursIndicatorBar
    },
    data: () => ({
      showConfirmCancel: false,
      cancellingWork: false,
      showError: false,
      errorText: null
    }),
    computed: {
      locationName() {
        return this.$t('locationNA')
      },
      disabled() {
        // We want to disable action buttons when work has already been cancelled or some other changes are being applied
        return this.work.status === 'cancelled' || this.work.status === 'cancelled' || this.disable
      },
      hours() {
        return Math.floor(this.totalWorkHours)
      },
      minutes() {
        const totalMinutes = this.totalWorkHours * 60
        return Math.floor(totalMinutes % 60)
      },
      totalWorkHours() {
        if(typeof this.work !== 'undefined' && this.work !== null && typeof this.work.total_hours !== 'undefined') {
          return this.work.total_hours
        }
        return 0
      }
    },
    methods: {
      emitClick() {
        this.$emit('itemClicked', this.work)
      },
      emitCancel() {
        this.showConfirmCancel = true
        // this.$emit('cancelClicked', this.work)
      },
      clearError() {
        this.showError = false
        this.errorText = null
      },
      /**
       * Cancelling of work, we do this in the item itself because it makes it simpler for us
       * If cancelling of work is successful we emit to parent that we want to get work for this staff again
       * NOTE: We do it this way because the top-most parent (staff->:uuid) holds the work array for the staff,
       * so that is what we need to update
       */
      confirmedCancel() {
        this.cancellingWork = true
        this.$store.dispatch('cancelWork', this.work).then(result => {
          if(result) {
            if(result.success === true) {
              this.$emit('getWorkByStaff')
              this.showConfirmCancel = false
              return
            }
            this.showError = true
            this.errorText = result.message
          }
        }).finally(() => {
          this.cancellingWork = false
					this.$emit('workCancelled')
        })
      }
    },
    watch: {
      cancellingWork(status) {
        // We emit to parent that this child is doing some work, making sure siblings have their actions disabled during this work
        this.$emit('cancellingWork', status)
      }
    }
  }
</script>
