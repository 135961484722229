<template>
  <div>
    <CreateStaffDialog v-model="modal" />
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <v-row
          class="pt-0 pb-0"
          style="height: 100vh;"
        >
          <v-col
            v-if="tab.key !== 'approve'"
            class="pt-0 pb-0 pr-0"
            cols="4"
            style="border-right: 1px solid #ddd;"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto"
            >
              <StaffList
                v-if="tab.key === 'active'"
                :load-items-callback="getStaff"
                :fixed-filters="activeStaffFilters"
                @staffClick="staffClicked"
                :call-to-actions="callToActions"
              />
              <StaffList
                v-if="tab.key === 'inactive'"
                :load-items-callback="getStaff"
                :fixed-filters="inactiveStaffFilters"
                @staffClick="staffClicked"
                :call-to-actions="callToActions"
              />
              <StaffList
                v-if="tab.key === 'noname'"
                :load-items-callback="getStaff"
                :fixed-filters="noNameStaffFilters"
                @staffClick="staffClicked"
                :call-to-actions="callToActions"
              />
            </v-card>
          </v-col>
          <v-col
            cols="8"
            class="grey lighten-3"
            v-if="tab.key !== 'approve'"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto"
            >
              <StaffProfile
                v-if="selectedStaff !== null"
                :key="staff.uuid"
                :staff="selectedStaff"
                class="pr-3"
              />
            </v-card>
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            v-if="tab.key === 'approve'"
          >
            <ConfirmWorkList
              :staff-work-list="workThatRequiresApproval"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import StaffList from "@/components/StaffList"
import CreateStaffDialog from "@/components/CreateStaffDialog"
import ConfirmWorkList from "@/components/staff/ConfirmWorkList"
import StaffProfile from "@/components/staff/StaffProfile"

export default {
	name: 'Staff',
	components: {
		ConfirmWorkList,
		StaffList,
		CreateStaffDialog,
		StaffProfile
	},
	data: () => ({
		modal: false,
		selectedStaff: null,
		activeStaffFilters: [
			{
				key: 'active',
				value: true,
				operator: 'equals',
				type: 'boolean'
			}
      	],
		inactiveStaffFilters: [
			{
				key: 'active',
				value: false,
				operator: 'equals',
				type: 'boolean'
			}
		],
		noNameStaffFilters: [
			{
				key: 'address.name_line',
				value: '',
				operator: 'equals',
				type: 'text'
			}
		]
	}),
	watch: {
		staff() {
			this.$store.commit('updateAppBarTabs', this.tabs)
		},
		work() {
			this.$store.commit('updateAppBarTabs', this.tabs)
		}
	},
	methods: {
		getStaff() {
			return this.$store.dispatch('getStaff').then(staff => {
				return staff
			}).catch(() => {
				return null
			})
		},
		staffClicked(staff) {
			this.selectedStaff = null
			this.selectedStaff = staff
		},
		exportStaff() {
			this.$store.commit('updateExportObjects', this.staff)
		},
		addStaff() {
			this.modal = true
		},
		checkIfAnother(value) {
			if(value) {
				this.addStaff()
			}
		}
	},
	computed: {
		callToActions() {
			return [
				{
					title: this.$t('addStaff'),
					callback: this.addStaff
				}
			]
		},
		query() {
			return this.$store.state.searchQuery
		},
		activeStaffCount() {
			return this.activeStaff !== null ? this.activeStaff.length : 0
		},
		inactiveStaffCount() {
			return this.inactiveStaff !== null ? this.inactiveStaff.length : 0
		},
		activeStaff() {

			if(this.staff === null || this.staff === undefined) {
				return null
			}

			return this.staff.filter(staff => {
				return staff.active && staff.address.name_line !== null && staff.address.name_line !== ''
			})
		},
		noNameStaff() {

			if(this.staff === null || this.staff === undefined) {
				return null
			}

			return this.staff.filter(staff => {
				return staff.address !== null && staff.address !== undefined && (staff.address.name_line === null || staff.address.name_line === '')
			})
		},
		inactiveStaff() {

			if(this.staff === null || this.staff === undefined) {
				return null
			}

			return this.staff.filter(staff => {
				return !staff.active
			})
		},
		staff() {
			return this.$store.state.staff
		},
		work() {
			return this.$store.state.work
		},
		workThatRequiresApproval() {
			if(this.work === null || this.work === undefined) {
				return null
			}
			return this.work.filter(w => w.requiresApproval)
		},
		workThatRequiresApprovalCount: function() {
			return this.workThatRequiresApproval !== null ? this.workThatRequiresApproval.length : 0
		},
		staffWithoutNameCount: function() {
			return this.noNameStaff !== null ? this.noNameStaff.length : 0
		},
		tabs() {
			return [
				{
					title: this.$t('active'),
					key: 'active',
					badge: {
						color: 'green',
						content: this.activeStaffCount
					}
				},
				{
					title: this.$t('inactive'),
					key: 'inactive',
					badge: {
						color: 'red',
						content: this.inactiveStaffCount,
					}
				},
				{
					title: this.$t('approveWork'),
					key: 'approve',
					badge: {
						color: 'blue',
						content: this.workThatRequiresApprovalCount
					}
				},
				{
					title: this.$t('noName'),
					key: 'noname',
					badge: {
						color: 'blue',
						content: this.staffWithoutNameCount
					}
				}
			]
		},
		selectedAppBarTab: function() {
			return this.$store.state.selectedAppBarTab
		},
	},
	created() {
		this.$store.dispatch('getWork', {
			startTimeInMillis: this.$moment().startOf('month').valueOf(),
			endTimeInMillis: this.$moment().endOf('month').valueOf()
		})
		this.$store.commit('updateAppBarExport', {
			color: 'pink',
			callback: this.exportStaff
		})
	},
	mounted() {
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	},
	destroyed() {
		this.$store.commit('updateAppBarTabs', [])
	}
}
</script>
