<template>
  <div>
    <div v-if="work === null">
      <template v-for="index in 10">
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-two-line"
          class="mx-auto"
          :key="index"
        />
      </template>
    </div>
    <div v-else-if="work !== null && work.length > 0">
      <v-list class="pa-0">
        <v-divider />
        <template v-for="(log, index) in slicedWork">
          <WorkListItem
            :key="log.uuid"
            :work="log"
            :staff="staff"
            :disable="disable"
            :hide-name="hideNames"
            @itemClicked="editWork"
            @cancellingWork="cancellingWork"
            @getWorkByStaff="updateWork"
            @workCancelled="workCancelled"
          />
          <v-divider :key="index" />
        </template>
      </v-list>
      <div
        v-if="slicedWork.length < work.length"
        v-intersect.quiet="loadMore"
      />
      <v-progress-circular
        v-if="isLoadingMore"
        indeterminate
        color="primary"
      />
    </div>
    <div
      class="pa-5"
      v-else
    >
      {{ $t('noWork') }}
    </div>
    <WorkDialog
      v-if="showWorkDialog"
      v-model="showWorkDialog"
      :type="actionType"
      :staff="staff"
      :work="selectedWork"
      @workHoursUpdated="updateWorkHours"
    />
  </div>
</template>

<script>

  import WorkListItem from "./WorkListItem"
  import WorkDialog from "./WorkDialog"

  export default {
    name: 'WorkList',
    props: {
      work: {
        type: Array,
        default: () => []
      },
      step: {
        type: Number,
        default: 0
      },
      staff: {
        type: Object,
        default: () => {}
      },
      hideNames: {
        type: Boolean,
        default: false
      }
    },
    components: {
      WorkDialog,
      WorkListItem
    },
    data: () => ({
      startIndex: 0,
      length: 50,
      isLoadingMore: false,
      showWorkDialog: false,
      actionType: null,
      selectedWork: null,
      confirmingCancel: false,
      disable: false
    }),
    computed: {
      slicedWork: function() {
        return this.work.slice(this.startIndex, this.length)
      }
    },
    methods: {
			workCancelled() {
        this.$emit('workCancelled')
      },
      updateWork() {
        // We signal top-most parent that we want to get this staff's work from graph
        this.$emit('updatedWorkHours', true)
      },
      cancellingWork(status) {
        this.disable = status
      },
      loadMore: function() {
        this.isLoadingMore = true
        this.length = this.length + parseInt(this.step)
      },
      addWork() {
        this.actionType = this.$t('add')
        this.showWorkDialog = !this.showWorkDialog
      },
      editWork(work) {
        this.actionType = 'Edit'
        this.selectedWork = work
        this.showWorkDialog = !this.showWorkDialog
      },
      updateWorkHours(success) {
        if(success) {
          this.showWorkDialog = false
          this.selectedWork = null

          const check = this.actionType ? this.actionType.toLowerCase() : this.actionType

          let message = ''

          switch (check) {
            case 'edit':
              message = 'Successfully edited work for ' + this.staff?.address.name_line
              break
            case 'add':
              message = 'Successfully added work for ' + this.staff?.address.name_line
              break
            default:
              message = 'Whatever you just did, it was successfully executed.'
              break
          }

          this.$store.commit('updateActionSuccess', {
            message: 'Success',
            subMessage: message
          })
          this.$emit('updatedWorkHours', true)
          this.$emit('refreshWorkPage', true)
        }
      }
    }
  }
</script>
