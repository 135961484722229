<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ workToConfirm.staff.address.name_line }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.staff.ssn }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('started') }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.start_date | moment("DD. MMMM YYYY, HH:mm") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('ended') }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.end_date | moment("DD. MMMM YYYY, HH:mm") }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('standardHours') }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.standard_hours !== null && workToConfirm.standard_hours !== undefined ? workToConfirm.standard_hours : 0 }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('eveningHours') }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.evening_hours !== null && workToConfirm.evening_hours !== undefined ? workToConfirm.evening_hours : 0 }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('weekendHours') }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.weekend_hours !== null && workToConfirm.weekend_hours !== undefined ? workToConfirm.weekend_hours : 0 }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t('totalHours') }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ workToConfirm.total_hours }} {{ $t('hours') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        large
        color="primary"
        :loading="approvingWork"
        :disabled="processing"
        @click="approveWork"
      >
        {{ $t('approve') }}
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
	name: "ConfirmWorkListItem",
	props: {
		workToConfirm: {
      type: Object,
      default: () => {}
    },
		processing: {
      type: Boolean
    },
    index: {
      type: Number,
      default: null
    },
	},
	data() {
		return {
			approvingWork: false
		}
	},
	watch: {
		processing(value) {
			if(value === false) {
				this.approvingWork = false
			}
		}
	},
	methods: {
		approveWork() {
			this.approvingWork = true
			this.$emit('approveWork', this.workToConfirm, this.index)
		}
	}
}
</script>

<style scoped>

</style>
