<template>
  <v-dialog
    max-width="500"
    :value="value"
    v-if="value"
    ref="workDialog"
    @input="updateValue"
    @click:outside="abort"
    :persistent="processing"
  >
    <v-card>
      <v-card-title>
        <span v-if="type.toLowerCase() === 'add'">
          {{ $t('addWork') }}
        </span>
        <span v-else>
          {{ $t('editWork') }}
        </span>
      </v-card-title>
      <v-card-subtitle v-if="staffPropExists">
        <span v-if="type.toLowerCase() === 'add'">
          {{ $t('addWork') }}
        </span>
        <span v-else>
          {{ $t('editWork') }}
        </span>
        <span>
          {{ $t('for') }} {{ staffName }}
        </span>
      </v-card-subtitle>
      <v-card-text>
        <v-container
          fluid
          class="px-0"
        >
          <v-row
            v-if="type.toLowerCase() === 'add' && !staffPropExists"
            dense
          >
            <v-col cols="12">
              <v-select
                :label="$t('staff')"
                v-model="selectedStaffID"
                :items="staffList"
                item-text="address.name_line"
                item-value="uuid"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select
                :label="$t('type')"
                v-model="selectedWorkType"
                :items="workTypeOptions"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="title text--primary"
            >
              {{ $t('clockIn') }}
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col cols="auto">
              <v-menu
                ref="dateClockInMenu"
                v-model="dateClockInMenu"
                :close-on-content-click="false"
                :return-value.sync="selectedClockInDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedClockInDate"
                    :label="$t('date')"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :hint="$t('dateFormatYYYYMMDDHint')"
                  />
                </template>
                <v-date-picker
                  v-model="selectedClockInDate"
                  v-if="dateClockInMenu"
                  no-title
                  scrollable
                  @click:date="$refs.dateClockInMenu.save(selectedClockInDate)"
                />
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <v-menu
                ref="timeClockInMenu"
                v-model="timeClockInMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="selectedClockInTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedClockInTime"
                    :label="$t('time')"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :hint="$t('timeFormatHHMMHint')"
                  />
                </template>
                <v-time-picker
                  v-if="timeClockInMenu"
                  v-model="selectedClockInTime"
                  format="24hr"
                  full-width
                  @click:minute="$refs.timeClockInMenu.save(selectedClockInTime)"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col
              cols="12"
              class="title text--primary"
            >
              {{ $t('clockOut') }}
            </v-col>
          </v-row>
          <v-row
            dense
          >
            <v-col cols="auto">
              <v-menu
                ref="dateClockOutMenu"
                v-model="dateClockOutMenu"
                :close-on-content-click="false"
                :return-value.sync="selectedClockOutDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedClockOutDate"
                    :label="$t('date')"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :hint="$t('dateFormatYYYYMMDDHint')"
                  />
                </template>
                <v-date-picker
                  v-model="selectedClockOutDate"
                  v-if="dateClockOutMenu"
                  no-title
                  scrollable
                  @click:date="$refs.dateClockOutMenu.save(selectedClockOutDate)"
                />
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <v-menu
                ref="timeClockOutMenu"
                v-model="timeClockOutMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="selectedClockOutTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="selectedClockOutTime"
                    :label="$t('time')"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :hint="$t('timeFormatHHMMHint')"
                  />
                </template>
                <v-time-picker
                  v-if="timeClockOutMenu"
                  v-model="selectedClockOutTime"
                  format="24hr"
                  full-width
                  @click:minute="$refs.timeClockOutMenu.save(selectedClockOutTime)"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row
            v-if="errorMessage"
            dense
            justify="center"
            class="pt-5"
          >
            <v-col
              class="error--text"
              cols="auto"
            >
              {{ errorMessage }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-2">
        <v-btn
          block
          color="success"
          @click="setWorkHour"
          :disabled="processing"
          :loading="processing"
          large
        >
          {{ type }} {{ $t('work') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

	export default {
		name: "WorkDialog",
    props: {
      value: {
        type: Boolean
      },
      type: {
        type: String,
        default: ''
      },
      work: {
        type: Object,
        default: () => {}
      },
      staff: {
        type: Object,
        default: () => {}
      }
    },
		data() {
			return {
				selectedClockInDate: new Date().toISOString().substr(0, 10),
				selectedClockOutDate: new Date().toISOString().substr(0, 10),
				dateClockInMenu: null,
				dateClockOutMenu: null,
				selectedClockInTime: '00:00',
				timeClockInMenu: null,
				selectedClockOutTime: '00:00',
				timeClockOutMenu: null,
				processing: false,
				errorMessage: null,
        selectedStaffID: null,
        selectedWorkType: 'log'
			}
		},
		watch: {
			selectedClockInDate(value) {
				this.selectedClockOutDate = value
			},
			work(value) {
				if(value && this.type && this.type.toLowerCase() === 'edit') {
					let start = new Date(this.work.start_date)
					let end = new Date(this.work.end_date)

					this.selectedClockInDate = start.toISOString().substr(0, 10)
					this.selectedClockOutDate = end.toISOString().substr(0, 10)
					this.selectedClockInTime = start.toISOString().substr(11, 5)
					this.selectedClockOutTime = end.toISOString().substr(11, 5)
				}
			}
		},
		mounted() {
      if(typeof this.staff !== 'undefined' && this.staff !== null) {
        this.selectedStaffID = this.staff.uuid
      }
      if(typeof this.work !== 'undefined' && this.work !== null) {
        if(this.type && this.type.toLowerCase() === 'edit') {
          let start = new Date(this.work.start_date)
          let end = new Date(this.work.end_date)

					/**
					 * The end time is set to the start time in the event that the worker has yet to clock out.
					 *  This prevents the user from needing to select a new end time without needing to scroll
					 *  from the Unix epoch.
					 **/
					if (end.getTime() === 0) { end = start }

          this.selectedClockInDate = start.toISOString().substr(0, 10)
          this.selectedClockOutDate = end.toISOString().substr(0, 10)
          this.selectedClockInTime = start.toISOString().substr(11, 5)
          this.selectedClockOutTime = end.toISOString().substr(11, 5)
          this.selectedWorkType = this.work.type
        }
      }
		},
		computed: {
      workTypes() {
        return this.$store.state.workTypes
      },
      workTypeOptions() {
        if(Array.isArray(this.workTypes) && this.workTypes.length > 0) {
          return this.workTypes.map(workType => {
            let text = workType.replace(/_/g, ' ')
            text = text.slice(0, 1).toUpperCase() + text.slice(1)
            return {
              text: text,
              value: workType
            }
          })
        }
        return []
      },
			graphUrl: function() {
				return this.$store.state.graphUrl
			},
			primaryColor() {
				return this.$store.getters.primaryColor
			},
      staffList() {
        return this.$store.state.staff.filter(staff => typeof staff.active !== 'undefined' && staff.active !== null && staff.active === true)
      },
      staffPropExists() {
        return typeof this.staff !== 'undefined' && this.staff !== null
      },
      staffName() {
        if(this.staffPropExists) {
          if(typeof this.staff.address !== 'undefined' && typeof this.staff.address.name_line !== 'undefined') {
            return this.staff.address.name_line
          }
        }
        return ''
      }
		},
		methods: {
			textColor(color) {
				return this.$store.getters.textButtonColor(color)
			},
			setWorkHour() {
				this.processing = true
				this.errorMessage = null

				const clockInTime = this.selectedClockInTime.split(':').map(i => parseInt(i))
				const clockOutTime = this.selectedClockOutTime.split(':').map(i => parseInt(i))
				const startTime = new Date(this.selectedClockInDate).setHours(clockInTime[0], clockInTime[1])
				const endTime = new Date(this.selectedClockOutDate).setHours(clockOutTime[0], clockOutTime[1])

				if(!this.type) {
					this.processing = false
					return
				}

				if(startTime > endTime) {
					this.errorMessage = this.$t('cannotAddWorkThatEndsBeforeItStarts')
					this.processing = false
					return
				}
				if(this.type.toLowerCase() === 'add' ) {
					let newWork = {
						type: this.selectedWorkType,
						staff: this.selectedStaffID,
						start_date: Math.round(startTime/1000).toString(),
						end_date: Math.round(endTime/1000).toString()
					}

					this.$store.dispatch('createNewWorkHours', newWork).then(response => {
						if(response) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'),
                subMessage: this.$t('newWorkHoursForStringWereAdded', {string: this.staffName})
              })
							this.resetValues()
							this.$emit('workHoursUpdated', true)
						}
					}).finally(() => {
						this.processing = false
					})

				} else if(this.type.toLowerCase() === 'edit') {
					let updatedWork = {
						uuid: this.work.uuid,
						type: this.selectedWorkType,
						staff: this.work.staff.uuid,
						start_date: Math.round(startTime/1000).toString(),
						end_date: Math.round(endTime/1000).toString()
					}

          this.$store.dispatch('updateWorkHours', updatedWork).then(response => {
						if(response) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'),
                subMessage: this.$t('workHoursForStringWereUpdated', {string: this.staffName})
              })
							this.resetValues()
							this.$emit('workHoursUpdated', true)
						}
					}).finally(() => {
						this.processing = false
					})
				} else {
					console.log(this.$t('notImplemented'))
					this.processing = false
				}
			},
			resetValues() {
				this.selectedClockOutDate = new Date().toISOString().substr(0, 10),
				this.selectedClockInDate = new Date().toISOString().substr(0, 10),
				this.selectedClockOutTime = '00:00'
				this.selectedClockInTime = '00:00'
				this.errorMessage = null
        this.selectedWorkType = 'log'
			},
			updateValue() {
				this.$emit('input', this.$refs.workDialog.value)
			},
			abort() {
				if(this.processing) {
					return
				}
				this.resetValues()
				this.$emit('input', false)
			},
		}
	}
</script>

<style>
.v-date-picker-table {
	height: auto !important;
}
</style>
